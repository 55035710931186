const mealTypesList: string[] = [
  'Breakfast',
  'Lunch',
  'Dinner',
  'Appetizer',
  'Dessert',
  'Snack',
  'Salad',
  'Side Dish',
  'Soup or Stew',
  'Quick',
  'Simple',
]

export default mealTypesList
